import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSliders, faPlay} from '@fortawesome/free-solid-svg-icons'

interface OauthApplication {
  service_name: string
  home_url: string
}

interface MyPageData {
  oauth_applications: Array<OauthApplication>
}

const initialMyPageData = {
  oauth_applications: []
}

const MyPage: React.FC = () => {
  const [myPageData, setMyPageData] = useState<MyPageData>(initialMyPageData)
  const {t} = useTranslation()

  useEffect(() => {
    const fetch = async () => {
      const result = await axios.get('/api/internal/users/my_page')
      setMyPageData(result.data)
    }
    fetch()
  }, [])
  return (
    <div style={{marginLeft: '1em'}}>
      <h2>{t('my_page.title')}</h2>
      <div style={{marginLeft: '1em'}}>
        <h3 style={{marginTop: '2em'}}>
          <FontAwesomeIcon icon={faSliders} />
          <span style={{marginLeft: '0.3em'}}>{t('my_page.procedure_menu')}</span>
        </h3>
        <ul>
          <li>
            <Link to="/users/change_password">{t('my_page.change_password')}</Link>
          </li>
          <li>
            <Link to="/users/change_language">{t('my_page.change_language')}</Link>
          </li>
          <li>
            <a href="/users/sign_out">{t('my_page.sign_out')}</a>
          </li>
        </ul>
      </div>
      <div style={{marginLeft: '1em'}}>
        <h3 style={{marginTop: '2em'}}>
          <FontAwesomeIcon icon={faPlay} />
          <span style={{marginLeft: '0.3em'}}>{t('my_page.services_you_are_using')}</span>
        </h3>
        <ul>
          {myPageData.oauth_applications.map((s) => (
            <li key={s.home_url}>
              <a href={s.home_url}>
                <span>{s.service_name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MyPage
