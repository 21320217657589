import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
interface SignInProps {
  csrf: string
  email?: string
}

const SignIn: React.FC<SignInProps> = ({csrf, email}) => {
  const {t} = useTranslation()

  const [inputEmail, setInputEmail] = useState(email || '')
  const updateInputEmail = (e: React.FormEvent<HTMLInputElement>) => {
    setInputEmail(e.currentTarget.value)
  }

  return (
    <div className="content-wrap">
      <div className="form-wrap">
        <div className="service-name">
          <img src="/img/base/ms-icon-70x70.png" />
          <span>{t('sign_in_default.service_title')}</span>
        </div>
        <div className="input-area">
          <form className="new_user" id="new_user" action="/users/sign_in" acceptCharset="UTF-8" method="post">
            <input type="hidden" name="authenticity_token" value={csrf} />
            <input
              autoFocus
              autoComplete="email"
              type="email"
              name="user[email]"
              id="user_email"
              value={inputEmail}
              onInput={updateInputEmail}
              placeholder={t('sign_in_default.email')}
              required
            />

            <input
              autoComplete="current-password"
              type="password"
              name="user[password]"
              id="user_password"
              placeholder={t('sign_in_default.password')}
              required
            />

            <label>
              <input name="user[remember_me]" type="hidden" value="0" />
              <input type="checkbox" name="user[remember_me]" id="user_remember_me" />
              {t('sign_in_default.remember_email')}
            </label>
            <button type="submit" name="commit" data-disable-with={t('sign_in_default.login')}>
              {t('sign_in_default.login')}
              <i className="fas fa-arrow-alt-circle-right fa-lg fa-pull-right"></i>
            </button>
          </form>
          <span
            dangerouslySetInnerHTML={{
              __html: t('sign_in_default.forget_password')
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t('sign_in_default.login_help')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SignIn
